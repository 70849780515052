<template lang="pug">
.grade
  .grade-container
    h2.grade-title(data-scroll)
      | WUWOW 獨創
      span.mx-1.grade-scondary 1~12 等分級制度
      | ，
      br
      | 讓家長更清楚掌握孩子的語言實力！

    //- 手機板顯示
    .grade-swiper
      swiper(
        ref="swiper",
        :options="swiperOption",
        @slideChange="slideChange"
      )
        swiper-slide(v-for="(item, index) in levelCircle")
          .grade-slide
            img(:src="item.initIcon")
            span {{ item.tag }}
            .slide-text
              p {{ item.textOne }}
              p {{ item.textTwo }}
            img.slide-triangle(
              :src="require('@/assets/event/trial/grade-triangle.png')"
            )
      //- 卡片 swiper 卡片
      transition(name="fade", mode="out-in")
        .grade-card(
          v-for="(item, index) in levelCard",
          v-if="index == selectLevel",
          :key="index"
        )
          h4 {{ item.titleOne }}
          h4 {{ item.titleTwo }}
            .word
              span.pill 單字量
              p {{ item.word }}
            .goal
              span.pill 教學目標
              ol
                li(v-for="(list, index) in item.goal") {{ list }}

    .grade-main
      .grade-wheel(data-scroll)
        //- 等級範圍圓圈
        button.grade-circle(
          v-for="(item, index) in levelCircle",
          :class="{ select: selectLevel == index }",
          @mouseenter.stop="setLevel(index)",
          @mouseleave.stop="startLoop()",
          type="button"
        )
          .circle-contain
            .grade-icon(name="fade")
              img.init(:src="item.initIcon")
              img.hover(:src="item.hoverIcon")
            span.level-tag {{ item.tag }}
            p {{ item.textOne }}
            p {{ item.textTwo }}

        //- 圓圈內卡片
        .card-wrapper
          transition(name="fade", mode="out-in")
            .grade-card(
              v-for="(item, index) in levelCard",
              v-if="index == selectLevel",
              :key="index"
            )
              h4 {{ item.titleOne }}
              h4 {{ item.titleTwo }}
              .word
                span.pill 單字量
                p {{ item.word }}
              .goal
                span.pill 教學目標
                ol
                  li(v-for="(list, index) in item.goal") {{ list }}

      img.grade-teacher(
        data-scroll,
        src="https://cdn2.wuwow.tw/arts/banners/grade-teacher.png"
      )
</template>

<style lang="scss" scoped>
@mixin sm {
  @media (min-width: 375px) {
    @content;
  }
}

@mixin md {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin xl {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin rwd-font($size: 12px, $size-md: 12px, $size-xl: 12px) {
  font-size: $size;

  @media (min-width: 768px) {
    font-size: $size-md;
  }

  @media (min-width: 1280px) {
    font-size: $size-xl;
  }
}

@mixin rwd-lh($lh: 1, $lh-md: 1, $lh-xl: 1) {
  line-height: $lh;

  @media (min-width: 768px) {
    line-height: $lh-md;
  }

  @media (min-width: 1280px) {
    line-height: $lh-xl;
  }
}

// 切換原始圖示跟 hover 圖示
*:has(.hover, .init) {
  > .hover {
    display: none;
  }

  // &:hover {
  //   > .init {
  //     display: none;
  //   }

  //   > .hover {
  //     display: inherit;
  //   }
  // }
}

.grade {
  background-image: url("~@/assets/event/trial/grade-halo.png"),
    linear-gradient(
      177.46deg,
      rgba(255, 255, 255, 1) 2.13%,
      rgba(255, 255, 255, 1) 106.93%
    );
  background-position: 0 -20px, 100% 100%;
  background-size: 364px 364px, 100% 100%;
  background-repeat: no-repeat;

  @include md {
    background-size: 435px 435px, 100% 100%;
  }

  .grade-scondary {
    color: var(--secondary-color);
  }

  .grade-title {
    margin-bottom: 60px;
    text-align: center;
    font-weight: 700;
    @include rwd-font(18px, 24px, 30px);
    @include rwd-lh(22px, 30px, 42px);

    /* 滑動動畫 */
    transition: opacity 0.8s ease-out, transform 0.8s ease-out;
    transition-delay: 1s;
    opacity: 0;
    transform: translatey(50%);

    &.animate-active {
      opacity: 1;
      transform: translateX(0);
    }
    /* 滑動動畫 end */
  }

  .grade-container {
    margin: 0 auto;
    // padding: 0 16px;

    @include md {
      padding: 0;
      /* max-width: 500px; */
      max-width: 600px;
    }

    @media (min-width: 1200px) {
      max-width: 1200px;
    }
  }

  .grade-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    padding: 0 16px;
    /* padding-bottom: 30%; */

    @media (min-width: 1280px) {
      padding-bottom: 30px;
    }
  }

  .grade-teacher {
    width: 100%;
    order: 1;
    /* z-index: 2; */
    /* position: absolute; */
    /* bottom: 8%; */
    /* pointer-events: none; */

    /* 滑動動畫 */
    transition: opacity 1.2s ease-out, transform 1.2s ease-out;
    transition-delay: 0s;
    opacity: 0;
    transform: translateX(-50%);

    &.animate-active {
      opacity: 1;
      transform: translateX(0);
    }

    /* 滑動動畫 end */

    @include md {
      bottom: 40px;
    }

    @media (min-width: 768px) {
      position: static;
      /* max-width: 606px; */
      max-width: 560px;
      /* transform: translateY(0%); */
      /* order: 1; */
    }
  }

    // 手機板 swiper-slide
  .grade-swiper{
    .grade-card{
      margin: 20px auto 10px;
    }

    @include md {
      display: none;
    }
  }

  // 讓 swiper 可以無限循環
  .swiper-slide {
    width: auto;
  }

  // swiper-slide-active 提供的選中 class name
  .swiper-slide-active {
    .grade-slide {
      span {
        background-color: var(--secondary-color);
      }
      p {
        color: var(--text-major);
      }

      .slide-triangle {
        display: initial;
      }
    }
  }

  .grade-slide {
    display: flex;
    flex-direction: column;
    align-items: center;

    cursor: pointer;

    img,
    svg {
      width: 36px;
      height: 36px;
    }

    span {
      margin: 10px auto;
      border-radius: 30px;
      padding: 4px 14px;
      background-color: var(--text-secondary);

      color: white;

      font-size: 16px;
      line-height: 22px;
      font-weight: 700;
      letter-spacing: 0.06em;
    }

    .slide-text {
      height: 40px;
      text-align: center;
    }

    p {
      color: var(--text-secondary);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.06em;
    }

    .slide-triangle {
      display: none;
      margin-top: 10px;
      width: 20px;
      height: 20px;
    }
  }

  .grade-wheel {
    display: none;
    order: 1;
    // width: 100%;
    width: 600px;
    height: 640px;

    position: relative;

    background-image: url("~@/assets/event/trial/grade-circle-arrow.png");
    background-repeat: no-repeat;
    background-position: 50% 50%;

    @include md {
      display: initial;
    }

    @media (min-width:1200px){
      order: 2;
    }

    /* 滑動動畫 */
    transition: opacity 0.8s ease-out, transform 0.8s ease-out;
    transition-delay: 1s;
    opacity: 0;
    transform: translatey(50%);

    &.animate-active {
      opacity: 1;
      transform: translateX(0);
    }
    /* 滑動動畫 end */
  }

  .card-wrapper {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }

  .grade-card {
    // position: absolute;
    // transform: translate(-50%, -50%);
    // top: 50%;
    // left: 50%;
    border-radius: 20px;

    width: 280px;
    text-align: center;

    padding: 20px 14px;
    box-shadow: 0px 0px 20px 0px #d8c8b64d;

    h4 {
      margin-bottom: initial;
      color: var(--text-major);
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0.06em;
    }

    .pill {
      background-color: var(--secondary-color);
      color: white;
      border-radius: 20px;
      padding: 4px 10px;

      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.06em;
    }

    .word {
      margin: 20px 0px;
      display: flex;
      align-items: center;

      p {
        margin-left: 10px;
        color: var(--text-major);
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.06em;
      }
    }

    .goal {
      text-align: start;
      .pill {
        margin-bottom: 10px;
      }

      ol {
        margin: initial;
        margin-top: 10px;
        list-style-type: decimal;
        list-style-position: outside;
        padding-left: 1em;
      }

      li {
        color: var(--text-major);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.06em;
      }
    }
  }

  // 等級範圍圓圈
  .grade-circle {
    position: absolute;
    --ring-width: 4px;
    --circle-diameter: 150px;
    width: var(--circle-diameter);
    height: var(--circle-diameter);

    border-radius: 50%;
    background-color: #7adefd4d;
    padding: var(--ring-width);

    transition: background-color 0.35s ease-out, background 0.35s ease-out;

    .circle-contain {
      width: 100%;
      height: 100%;

      border-radius: 50%;

      padding: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: white;
      transition: background-color 0.35s ease-out;
    }

    img,
    svg {
      width: 36px;
      height: 36px;
    }

    span {
      transition: background-color 0.35s ease-out, color 0.35s ease-out;

      margin: 4px auto;
      padding: 4px 14px;
      border-radius: 30px;
      background-color: var(--secondary-color);

      color: white;
      font-size: 14px;
      line-height: 20px;
      font-weight: 700;
      letter-spacing: 0.06em;
      text-align: center;
    }

    p {
      transition: color 0.35s ease-out;

      color: var(--text-secondary);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.06em;
      text-align: center;
    }

    &:nth-child(1) {
      top: 0%;
      left: 50%;
      transform: translateX(-50%);
    }
    &:nth-child(2) {
      top: 125px;
      right: 0%;
    }
    &:nth-child(3) {
      bottom: 125px;
      right: 0%;
    }
    &:nth-child(4) {
      bottom: 0%;
      left: 50%;
      transform: translateX(-50%);
    }
    &:nth-child(5) {
      bottom: 129px;
      left: 0%;
    }
    &:nth-child(6) {
      top: 125px;
      left: 0%;
    }

    &:hover {
      background: linear-gradient(180deg, #75cdec 0%, #43e4db 100%);
      .circle-contain {
        background: radial-gradient(
          50% 50% at 50% 50%,
          #def6ff 64.06%,
          #fff 100%
        );
      }

      p {
        color: var(--text-major);
      }
    }

    &.select {
      background: initial;
      background-color: #29acda;
      .circle-contain {
        background: initial;
        background-color: var(--secondary-color);
      }
      span {
        background-color: white;
        color: var(--secondary-color);
      }
      p {
        color: white;
      }

      .init {
        display: none;
      }

      .hover {
        display: inherit;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.35s ease-out, transform 0.35s ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(25%);
}

.fade-enter-to,
.fade-leave {
  opacity: 1;
}
</style>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';

export default {
  name: 'Grade',

  components: { swiper, swiperSlide },

  data: () => ({
    swiper: {},
    selectLevel: 0,
    // for 自動播放
    intervalId: 0,

    levelCircle: [
      {
        initIcon: require('@/assets/event/trial/grade-level-1.png'),
        hoverIcon: require('@/assets/event/trial/grade-level-1-white.png'),
        tag: 'Level 1',
        textOne: 'Pre1',
        textTwo: '零基礎階段',
      },
      {
        initIcon: require('@/assets/event/trial/grade-level-2.png'),
        hoverIcon: require('@/assets/event/trial/grade-level-2-white.png'),
        tag: 'Level 2~3',
        textOne: 'A1 入門階段',
        textTwo: null,
      },
      {
        initIcon: require('@/assets/event/trial/grade-level-3.png'),
        hoverIcon: require('@/assets/event/trial/grade-level-3-white.png'),
        tag: 'Level 4~5',
        textOne: 'A1+ 基礎階段',
        textTwo: null,
      },
      {
        initIcon: require('@/assets/event/trial/grade-level-4.png'),
        hoverIcon: require('@/assets/event/trial/grade-level-4-white.png'),
        tag: 'Level 6~7',
        textOne: 'A2 初階階段',
        textTwo: null,
      },
      {
        initIcon: require('@/assets/event/trial/grade-level-5.png'),
        hoverIcon: require('@/assets/event/trial/grade-level-5-white.png'),
        tag: 'Level 8~10',
        textOne: 'B1 進階階段',
        textTwo: null,
      },
      {
        initIcon: require('@/assets/event/trial/grade-level-6.png'),
        hoverIcon: require('@/assets/event/trial/grade-level-6-white.png'),
        tag: 'Level 11~12',
        textOne: 'B2 高階階段',
        textTwo: null,
      },
    ],

    levelCard: [
      {
        titleOne: '走出英文排斥期',
        titleTwo: null,
        word: '約300字',
        goal: [
          '激發學生對英文的興趣',
          '建立學習英文的自信心',
          '賦予英文文字能力的認知',
        ],
      },
      {
        titleOne: '學習以「英文」思考，建立語感',
        titleTwo: null,
        word: '約500 字',
        goal: [
          '聽懂生活基本用語',
          '簡易日常對答，對於熟悉場景進行簡單回應',
          '理解並使用簡單句子句型',
        ],
      },
      {
        titleOne: '培養簡易對話能力，練習溝通',
        titleTwo: null,
        word: '約700字',
        goal: [
          '銜接過渡A1及A2難易度',
          '加強對簡單主題的延伸思考力',
          '學習使用更完整的簡易句型',
        ],
      },
      {
        titleOne: '具備簡易開放式對話的',
        titleTwo: '英文溝通能力',
        word: '約1400字',
        goal: [
          '短篇(80字)閱讀訓練',
          '能處理簡短社交互動',
          '理解生活常用情境對話',
          '條列式訊息或使用簡單句型描述日常事物',
        ],
      },
      {
        titleOne: '聆聽英文廣播、閱讀簡易書刊，',
        titleTwo: '具備與母語人士日常溝通能力',
        word: '約2600 字',
        goal: [
          '理解長篇內容與介紹說明',
          '對於熟悉事物可清楚表達想法與交流訊息',
          '閱讀(150字)技巧培養與理解訓練',
          '簡易文字表達個人經歷與熟悉主題',
        ],
      },
      {
        titleOne: '聽懂英文演講、報導、節目，',
        titleTwo: '具備與母語人士流暢溝通能力',
        word: '約3200字',
        goal: [
          '能處理較複雜或抽象主題並進行交流討論',
          '清楚表達自身觀點，即時用英文互動',
          '提升閱讀素養(200字)',
          '針對特定觀點提出支持及反對理由',
        ],
      },
    ],

    swiperOption: {
      slidesPerView: 'auto',
      centeredSlides: true,
      slideToClickedSlide: true,
      spaceBetween: 14,
      loop: true,
      loopFillGroupWithBlank: true,
      observer: true,
    },
  }),

  mounted() {
    this.swiper = this.$refs.swiper.swiper;
    this.startLoop();
  },

  methods: {
    // onSwiper(swiper) {
    //   this.swiper = swiper;
    //   console.log('this.swiper', this.swiper);
    // },
    slideChange() {
      this.selectLevel = this.swiper.realIndex;
    },
    // 切換到指定 level
    setLevel(levelIndex) {
      this.selectLevel = levelIndex;
      this.swiper.slideTo(levelIndex, 500);

      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
    },
    startLoop() {
      this.intervalId = setInterval(this.nextLevel, 4000);
    },
    nextLevel(){
      const nextLevel = (this.selectLevel + 1) % this.levelCircle.length;
      this.selectLevel = nextLevel;
      this.swiper.slideTo(nextLevel, 500);
    },
  },
};
</script>
