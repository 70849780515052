var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "grade"
  }, [_c('div', {
    staticClass: "grade-container"
  }, [_vm._m(0), _c('div', {
    staticClass: "grade-swiper"
  }, [_c('swiper', {
    ref: "swiper",
    attrs: {
      "options": _vm.swiperOption
    },
    on: {
      "slideChange": _vm.slideChange
    }
  }, _vm._l(_vm.levelCircle, function (item, index) {
    return _c('swiper-slide', [_c('div', {
      staticClass: "grade-slide"
    }, [_c('img', {
      attrs: {
        "src": item.initIcon
      }
    }), _c('span', [_vm._v(_vm._s(item.tag))]), _c('div', {
      staticClass: "slide-text"
    }, [_c('p', [_vm._v(_vm._s(item.textOne))]), _c('p', [_vm._v(_vm._s(item.textTwo))])]), _c('img', {
      staticClass: "slide-triangle",
      attrs: {
        "src": require('@/assets/event/trial/grade-triangle.png')
      }
    })])]);
  }), 1), _c('transition', {
    attrs: {
      "name": "fade",
      "mode": "out-in"
    }
  }, _vm._l(_vm.levelCard, function (item, index) {
    return index == _vm.selectLevel ? _c('div', {
      key: index,
      staticClass: "grade-card"
    }, [_c('h4', [_vm._v(_vm._s(item.titleOne))]), _c('h4', [_vm._v(_vm._s(item.titleTwo)), _c('div', {
      staticClass: "word"
    }, [_c('span', {
      staticClass: "pill"
    }, [_vm._v("單字量")]), _c('p', [_vm._v(_vm._s(item.word))])]), _c('div', {
      staticClass: "goal"
    }, [_c('span', {
      staticClass: "pill"
    }, [_vm._v("教學目標")]), _c('ol', _vm._l(item.goal, function (list, index) {
      return _c('li', [_vm._v(_vm._s(list))]);
    }), 0)])])]) : _vm._e();
  }), 0)], 1), _c('div', {
    staticClass: "grade-main"
  }, [_c('div', {
    staticClass: "grade-wheel",
    attrs: {
      "data-scroll": ""
    }
  }, [_vm._l(_vm.levelCircle, function (item, index) {
    return _c('button', {
      staticClass: "grade-circle",
      class: {
        select: _vm.selectLevel == index
      },
      attrs: {
        "type": "button"
      },
      on: {
        "mouseenter": function ($event) {
          $event.stopPropagation();
          return _vm.setLevel(index);
        },
        "mouseleave": function ($event) {
          $event.stopPropagation();
          return _vm.startLoop();
        }
      }
    }, [_c('div', {
      staticClass: "circle-contain"
    }, [_c('div', {
      staticClass: "grade-icon",
      attrs: {
        "name": "fade"
      }
    }, [_c('img', {
      staticClass: "init",
      attrs: {
        "src": item.initIcon
      }
    }), _c('img', {
      staticClass: "hover",
      attrs: {
        "src": item.hoverIcon
      }
    })]), _c('span', {
      staticClass: "level-tag"
    }, [_vm._v(_vm._s(item.tag))]), _c('p', [_vm._v(_vm._s(item.textOne))]), _c('p', [_vm._v(_vm._s(item.textTwo))])])]);
  }), _c('div', {
    staticClass: "card-wrapper"
  }, [_c('transition', {
    attrs: {
      "name": "fade",
      "mode": "out-in"
    }
  }, _vm._l(_vm.levelCard, function (item, index) {
    return index == _vm.selectLevel ? _c('div', {
      key: index,
      staticClass: "grade-card"
    }, [_c('h4', [_vm._v(_vm._s(item.titleOne))]), _c('h4', [_vm._v(_vm._s(item.titleTwo))]), _c('div', {
      staticClass: "word"
    }, [_c('span', {
      staticClass: "pill"
    }, [_vm._v("單字量")]), _c('p', [_vm._v(_vm._s(item.word))])]), _c('div', {
      staticClass: "goal"
    }, [_c('span', {
      staticClass: "pill"
    }, [_vm._v("教學目標")]), _c('ol', _vm._l(item.goal, function (list, index) {
      return _c('li', [_vm._v(_vm._s(list))]);
    }), 0)])]) : _vm._e();
  }), 0)], 1)], 2), _c('img', {
    staticClass: "grade-teacher",
    attrs: {
      "data-scroll": "",
      "src": "https://cdn2.wuwow.tw/arts/banners/grade-teacher.png"
    }
  })])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h2', {
    staticClass: "grade-title",
    attrs: {
      "data-scroll": ""
    }
  }, [_vm._v("WUWOW 獨創"), _c('span', {
    staticClass: "mx-1 grade-scondary"
  }, [_vm._v("1~12 等分級制度")]), _vm._v("，"), _c('br'), _vm._v("讓家長更清楚掌握孩子的語言實力！")]);

}]

export { render, staticRenderFns }